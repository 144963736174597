<template lang="html">
  <section
    ref="column"
    v-if="items && items.length > 0"
    class="column"
    :style="{ '--height': this.width + 'px' }"
  >
    <div ref="block" class="title">
      <router-link v-if="id" :to="{ name: 'Channel', params: { id: id } }">{{
        title
      }}</router-link>
      <span v-else>{{ title }}</span>
    </div>
    <div
      class="block"
      :class="item.class.toLowerCase()"
      v-for="item in contents"
      :key="item.id"
    >
      <div class="inner" :class="{ double: active }">
        <template v-if="item.class == 'Image'">
          <img :src="item.image.display.url" :alt="item.title" />
        </template>
        <template v-if="item.class == 'Link' || item.class == 'Attachment'">
          <a class="center hf" v-if="item.source" :href="item.source.url" target="_blank">
            <img :src="item.image.display.url" :alt="item.title" />
          </a>
          <img class="center hf" v-else :src="item.image.display.url" :alt="item.title" />
        </template>
        <template v-if="item.class == 'Channel'">
          <div class="center">
            <router-link :to="{ name: 'Channel', params: { id: item.slug } }">{{
              item.title
            }}</router-link>
          </div>
        </template>
      </div>
    </div>
  </section>
  <section v-else>
    <div class="loader">
      🌀
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    active: Boolean,
    items: Array
  },
  data() {
    return {
      width: 0
    };
  },
  computed: {
    contents() {
      let reverse = this.items;
      return reverse;
    }
  },
  mounted() {
    this.getWidth();
    window.addEventListener("resize", this.getWidth);
  },
  updated() {
    this.getWidth();
  },
  methods: {
    getWidth() {
      console.log('test');
      // console.log("get width", this.$refs.block.offsetWidth);
      this.width = this.$refs.block.offsetWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  display:inline-block;
  position:relative;
  padding:1rem 0;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform:rotate(0);
  }
  100% {
    transform:rotate(360deg);
  }
}
section {
  flex: 1 1 0;
  height: 100%;
  padding: 0 1rem;
  transition: flex 1s;
  // scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  @media screen and (max-width: 768px) {
    flex: 0 0 100% !important;
    font-size: 1rem;
    height: auto;
    overflow-y: auto;
    &.active {
      flex: 0 0 100% !important;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &.active {
    flex: 2 1 0;
    .block {
      .caption {
        display: block;
      }
    }
  }

  .title {
    position: sticky;
    padding: 1rem 0;
    top: 0;
    z-index:99;
    background: white;
    line-height: 1.25rem;
  }

  .block {
    width: 100%;
    padding: 1rem 0;

    &:nth-of-type(2) {
      padding-top: 0;
    }

    .inner {
      border: 1px solid black;
      width: 100%;
      position: relative;
      padding-bottom:100%;
      overflow: hidden;
      transition: height 1s;
      display: flex;
      align-items: center;
      justify-content: center;
      .center {
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        text-align: center;
        width:100%;
        &.hf {
          height:100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top:0;
        left:0;
      }
    }

    .caption {
      display: none;
      width: 100%;
      word-break: break-all;
      line-height: 1.25;
      overflow: hidden;
      margin-top: 0.5rem;
    }
  }
}
</style>
