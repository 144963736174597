<template>
  <main class="home" :style="{ '--height': this.width + 'px' }">
    <section
      class="info"
      :class="{ active: activeIndex == 0 }"
      @mouseenter="activeIndex = 0"
    >
      <div class="title">2021</div>
      <div v-if="website && website.length > 0">
        <vue-simple-markdown :source="website"></vue-simple-markdown>
      </div>
      <br /><br />
      <p v-if="website && website.length > 0">
        <a href="https://www.epfl.ch/schools/enac/" target="_blank">
          <img height="30" src="./../assets/Logo_EPFL.svg" alt="www.epfl.ch" />
        </a>
      </p>
    </section>
    <column
      title="🌀 I - MATTER & MATERIAL"
      id="i-matter-material"
      :class="{ active: activeIndex == 1 }"
      @mouseenter.native="activeIndex = 1"
      :items="landscape"
    />
    <column
      title="🌀 II - LANDSCAPE"
      id="ii-landscape"
      :class="{ active: activeIndex == 2 }"
      @mouseenter.native="activeIndex = 2"
      :items="material"
    />
    <column
      title="🌀 III - CRITICAL MASS"
      id="iii-critical-mass"
      :class="{ active: activeIndex == 3 }"
      @mouseenter.native="activeIndex = 3"
      :items="critical"
    />
  </main>
</template>

<script>
import Column from "@/components/Column.vue";
import Arena from "are.na";
const arena = new Arena({ accessToken: process.env.VUE_APP_ARENA });
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
export default {
  name: "Home",
  components: {
    Column,
  },
  data() {
    return {
      activeIndex: 0,
      width: 0,
      website: [],
      landscape: [],
      material: [],
      critical: [],
    };
  },
  beforeCreate() {
    arena
      .channel("website-8wugzsrrwnq")
      .get()
      .then((chan) => {
        this.website = chan.metadata.description;
      })
      .catch((err) => console.log(err));
    arena
      .channel("i-matter-material")
      .get()
      .then((chan) => {
        this.landscape = chan.contents.reverse();
      })
      .catch((err) => console.log(err));
    arena
      .channel("ii-landscape")
      .get()
      .then((chan) => {
        this.material = chan.contents.reverse();
      })
      .catch((err) => console.log(err));
    arena
      .channel("iii-critical-mass")
      .get()
      .then((chan) => {
        this.critical = chan.contents.reverse();
      })
      .catch((err) => console.log(err));
  },
  filters: {
    date(value) {
      return dayjs().to(dayjs(value));
    },
  },
};
</script>

<style lang="scss">
.vue-simple-markdown {
  white-space: pre-wrap;
}
main.home {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  section.info {
    flex: 1 1 0;
    height: 100%;
    padding: 0 1rem;
    transition: flex 1s;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    &.active {
      flex: 2 1 0;
    }
    .title {
      padding-top: 1rem;
      padding-bottom: 1rem;
      line-height: 1.25rem;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    section.info {
      flex: 0 0 100% !important;
      height: auto;
    }
  }
}
</style>
