<template>
  <div id="app" :class="{ blur: lightbox.status }">
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/archive">Archive</router-link>
      <a href="https://www.are.na/art-fundaments/" target="_blank">Are.na</a>
    </nav>
    <router-view :key="$route.fullPath" />
    <div
      v-if="lightbox.status === true"
      class="lightbox"
      @click.self="closeLightBox"
    >
      <div class="close-btn" @click="closeLightBox">
        X
      </div>
      <div class="lightbox-container">
        <img :src="lightbox.image" />
        <a v-if="lightbox.source" :href="lightbox.source" target="_blank"
          >Source</a
        >
        <div class="description">{{ lightbox.title }}</div>
        <div class="description">{{ lightbox.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lightbox: {
        status: false,
        iamge: null,
        title: null,
        description: null
      }
    };
  },
  mounted() {
    this.$root.$on("lightbox", this.toggleBlur);
  },
  methods: {
    toggleBlur(lightbox) {
      this.lightbox = lightbox;
      console.log(lightbox);
    },
    closeLightBox() {
      let lightbox = {
        status: false,
        image: null,
        description: null,
        title: null,
        source: null
      };
      this.lightbox = lightbox;
      // this.lightbox.status = false;
    }
  }
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
#app {
  font-family: "Helvetica", Helvetica, Arial, sans-serif;
  line-height: auto;
  font-size: 1.3vmax;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.5rem;
  }
  &.blur {
    main,
    nav {
      filter: blur(30px) saturate(5) hue-rotate(30deg);
    }
  }
}
* {
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:link {
  color:black;
}
p,
.mb {
  margin-bottom: 1rem;
}
.mbd {
  margin-bottom: 4rem;
}

body,
html,
#app {
  width: 100%;
  height: 100%;

}

main,
nav {
  transform: translate3d(0, 0, 0);
  transition: filter 0.7s ease-in-out;
  font-size: 1.2rem;
}

.lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .close-btn {
    position: fixed;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;
  }
  .lightbox-container {
    max-width: 40vw;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 2rem;
    @media screen and (max-width: 1024px) {
      max-width: 70vw;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  img {
    margin-bottom: 1rem;
    display: block;
    width: 100%;
    // margin-left: auto;
    // margin-right: auto;
  }
  .description {
    margin-top: 1rem;
    margin-bottom: 0;
    height: auto;
  }
}

nav {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: auto;
  background: rgb(255, 255, 255);
  z-index: 99;
  // background: linear-gradient(
  //   0deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(255, 255, 255, 1) 60%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  box-shadow: 0 0 10px 10px white;
  a {
    padding-right: 1rem;
    padding-bottom: 1rem;
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: auto;
    left: auto;
    padding: 1rem;
  }
}
</style>
